.App {
  text-align: center;
  background-color: rgb(254, 254, 249);
  height: 100%;
  background-size: 100%;
} 





















