/* ogólne  */

@font-face {
	font-family: OPTIMistral-Graff;
	src: url('../public/images/fonts/mistral-graff/OPTIMistral-Graff.otf') format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, DM Sans, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',Helvetica, Arial,"Apple Color Emoji", "Segoe UI Emoji",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* header */
.header {
	display: block;
	padding: auto;
	margin: 0 auto;
	max-height: 250px;
	width: 100%;
	background-position: center;
}

.header_top {
	display: flex;
	/* margin: 0 auto; */
	padding: 5px 10px;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 3;
	background: url("../public/images/header.png") center bottom repeat-x;
}

.header_top div:not(:last-child) {
	padding-right: 30px;
}

.link {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.143;
	letter-spacing: 0.02em;
	color: #212121;
}

.shadows {
	padding: auto;
	margin: 0 auto;
	z-index: 3;
	background: url("../public/images/header.png") center bottom repeat-x;
}

.heder_bottom {
	display: flex;
	max-width: 80%;
	padding: 100px 80px 10px;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;

}

.logo {
	width: 100px;
	height: 100px;
	border-radius: 35%;
}

.logo__text {
	font-family: "OPTIMistral-Graff";
	font-style: italic;
	font-weight: 700;
	font-size: 54px;
	line-height: 2.25;
	letter-spacing: 0.06em;
	color: rgb(0,199,71);
}
.logo__text-color {
	color: rgb(237,28,36);
}

.heder_nav_list {
	display: flex;
}

.heder_nav_list > a:not(:last-child) {
	margin-right: 50px;
}

.drop {
	position: relative;
}

.drop ul {
	position: absolute;
	bottom: -30%;
	min-width: 100px;
	text-align: center;
	background-color: #373c58;
	box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.25);
	margin-top: 10px;
	border-radius: 7px;
}

.drop ul li {
	padding: 0.5rem;
}

.drop ul li a {
	display: inline-block;
	width: 100%;
	height: 100%;
	color: rgb(212, 246, 246);
}

.drop ul li:hover {
	background: #272b3f;
	border-radius: 7px;
}

.btn_ikon {
	position: relative;
	font-family: "OPTIMistral-Graff";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 2.25;
	letter-spacing: 0.06em;
	color: rgb(2, 109, 39);
}

.btn_ikon_svg {
	display: flex;
	justify-content: center;
	width: 60px;
	height: 50px;
	border-radius: 30%;
	fill: rgb(2, 109, 39);
}

.btn_ikon:hover
.btn_ikon:hover
.btn_ikon:focus
.btn_ikon:focus {
	cursor: pointer;
	color: deeppink;
	fill: deeppink;
}

.btn_ikon:active
.btn_ikon:active {
	cursor: pointer;
	color: deeppink;
	fill: deeppink;
}
/* header */
/* list produkt  */
.box__products {
	display: block;
	position: relative;
	max-width: 80%;
	margin: auto;
	justify-content: flex-start;
	font-family: "Droid Sans";
}

.box__title{ 
    display: flex;
    justify-content: center;

}

.product__list {
	display: flex;
	padding-top: 30px;
	justify-content: center;
}

.product__list >a:not(:last-child){
    margin-right: 60px;
}


.product__list__item {
	display: block;
	fill: rgb(28, 24, 29);
	background-color: rgb(2, 109, 39);
	border-radius: 20%;
}

.product__list__item > p {
	display: flex;
	font-family: "Droid Sans";
	font-size: 22px;
	padding: 20px 20px;
	justify-content: center;
	align-items: center;
}

.product__list__item:hover,
.product__list__item:focus {
	fill: deeppink;
	color: deeppink;
}

/* list produkt  */
/* contacts */
.adress__title{
  font-family: 'Roboto';
  font-size: 50px;

}

.adress__text{
  font-family: "Roboto";
  font-family: 16px;
  font-weight: 500;
  line-height: 1.50;
  padding-top: 30px;
}

.adress__text>li:not(:last-child) {
  padding-bottom: 30px;

}
/* contacts */
/* footer */
.footer {
  background-color: rgb(212, 212, 212);
}
.footer-wrapper {
  display: block;
  max-width: 80%;
  padding: 30px 80px 10px;
margin: 0 auto;
  
}
.footer-top {
  display:flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items:center;
}
.footer-top div:not(:last-child) {
  margin-right: 100px;
}

.box-text {
  min-height: 150px;
  padding-top: 20px;
}

.footer__title{
  font-family: 'Roboto';
  font-size: 16px;
}

.footer__text {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.06em;
}

.footer-bootom {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #7F9799;
  justify-content: center;
  align-items: center;
}

.bootom__list {
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
  justify-content: center;
  align-items: center;
}

.bootom__list__item {
  font-family: "Roboto";
  font-size: 12px;

}

.bootom__list__item:not(:last-child) {
  margin-right: 30px;
}

.socialMedia__list {
  display: inline-flex;
  padding-top: 20px;
}
.socialMedia__list p{
  padding-bottom: 10px;
}

.socialMedia__list__item:not(:last-child)  {
  margin-right: 20px;
}

.socialMedia__text{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.06em;
  padding-bottom: 20px;
}

.social__logo__olx {
  width:40px;
height:40px;
  border-radius: 50%;
}

.social__logo__facebook {
  fill: #2374e1;
  background-color: #fff;
  border-radius: 15%;
}
/* footer */

/* home */

.wraper__home{
    /* display: flex; */
    max-width: 80%;
    padding: 20px 80px 10px;
    margin: 0 auto;
}

.home_title{
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.05em;
	color: #212121;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;

}
.home {
    display: flex;
    /* max-width: 80%; */
    /* padding: 50px 80px 10px; */
    /* margin: 0 auto; */
}
.home_picture{
    display: flex;
    margin-right: 20px;
}

.home__list {
    display: flex;
    /* max-width: 80%; */
    padding: 10px 80px 10px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}
.home_list_iteam{

}
.home__list p {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align:center;
	color: #212121;
    margin-bottom: 20px;
}
.home__text{
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align:justify;
	color: #212121;
    padding-bottom: 30px;
    /* justify-content: center; */
	/* align-items: center; */
}

/* home */
/* products */
.conteiner {
	min-height: 90vh;
	padding: 50px 0;
}

.box {
	position: relative;
	display: flex;
	max-width: 80%;
	margin: auto;
	justify-content: flex-start;
	font-family: "Droid Sans";
}

.title {
	font-family: 'Roboto';
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: #000;
	padding: 20px 10px;
}


.box__list__item {
	display: flex;
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100px;
	width: 120px;
	background-color: rgb(0,199,71);
	color: #000;
	border-radius: 40%;
	padding: 10px;
}

.box__list__item:hover,
.box__list__item:focus
{
	color: deeppink;
}
.box__left {
	top: auto;
}

.box__left:not(:last-child) {
	margin-right: 30px;
}

.box__right {
	display: flex;
	padding: 20px;
}


.box__header {
	display: block;
}

.box__header__title {
	display: block;
	font-size: 24px;
	padding: 20px 0;
}

.box__text {
	padding: 0px 0 0 20px;
}
.box__text__title {
	font-size: 24px;
	padding: 20px 0;
}
.box__text__description {
	font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align:justify;
	color: #212121;
    padding-bottom: 30px;
}
.box__text__list li {
	font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-align:justify;
	color: #212121;
    padding-bottom: 30px;
	list-style-type:circle;
}
/* products */